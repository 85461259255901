<template>
  <app-page v-if="homepage?.id" :data="homepage" />
</template>

<script lang="ts" setup>
import { storeToRefs } from "pinia";
import AppPage from "~/modules/shared/components/AppPage.vue";
import { useGlobalStore } from "~/modules/shared/stores/global";

const globalStore = useGlobalStore();
const { homepage } = storeToRefs(globalStore);
</script>
